import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import "./registerServiceWorker";
import setupInterceptors from "./services/setupInterceptors";
import Toast from "vue-toastification";
import "./assets/custom-toast-styles.css";
import "./assets/customStyle.css"
import VueHtmlToPaper from "vue-html-to-paper";
import { VueReCaptcha } from "vue-recaptcha-v3";
import AOS from "aos";
import "aos/dist/aos.css";

import Pusher from 'pusher-js';
import moment from 'moment-timezone';
import VueEasyLightbox from 'vue-easy-lightbox'
import CKEditor from '@mayasabha/ckeditor4-vue3';

 
moment.tz.setDefault('Asia/Jakarta');

setupInterceptors(store);
loadFonts();
AOS.init();

// Initialize Pusher
const pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
  cluster: process.env.VUE_APP_PUSHER_CLUSTER,
  forceTLS: false, // Gunakan koneksi WSS bahkan ketika tab tidak aktif
  encrypted: false,
  authEndpoint: '/pusher/auth',
});

const app = createApp(App);
app.config.productionTip = false
// Tambahkan Pusher ke properties global
app.config.globalProperties.$pusher = pusher;

app.use(router)
  .use(vuetify)
  .use(store)
  .use(Toast)
  .use(CKEditor)
  .use(VueEasyLightbox)
  .use(VueReCaptcha, { siteKey: "6LdnjY0cAAAAAKvyqd2HQnq57EWgxObeTwZZjRws" })
  .use(VueHtmlToPaper, {
    name: "_blank",
    specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
    styles: [
      "https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css",
    ],
    timeout: 1000,
    autoClose: false,
  })
  .mount("#app");
