import axios from "axios";

const instance = axios.create({
  //baseURL: "http://localhost:8000/api",

  baseURL: "https://tesnet-api.kosemagcl.my.id/public/api/",


  headers: {
    "Content-Type": "application/json",
  },
});

export default instance;
