// router/routeConfig.js

const ADMINISTRATIVE_ROLE = "Administrative";
const ATTENDANCECHECKER_ROLE = "AttendanceChecker";
const ATTENDANCEGUEST_ROLE = "AttendanceGuest";

const USER_ROLE = "User";

const protectedRoutesAdministrative = [
  {
    icon: "mdi-view-dashboard",
    name: "Dashboard *",
    path: "/administrative-dashboard",
    component: () => import("../views/administrative/dashboard.vue"),
    meta: { requiresAuth: true, role: ADMINISTRATIVE_ROLE },
  },
  {
    icon: "mdi-account",
    name: "Users *",
    path: "/administrative-user-management",
    component: () => import("../views/administrative/userManagement.vue"),
    meta: { requiresAuth: true, role: ADMINISTRATIVE_ROLE },
  },
  {
    icon: "mdi-format-list-bulleted-triangle",
    name: "Jenis Simpanan *",
    path: "/administrative-jenis-simpanan",
    component: () => import("../views/administrative/jenisSimpanan.vue"),
    meta: { requiresAuth: true, role: ADMINISTRATIVE_ROLE },
  },  
  {
    icon: "mdi-account",
    name: "Jenis Pinjaman *",
    path: "/administrative-jenis-pinjaman",
    component: () => import("../views/administrative/jenisPinjaman.vue"),
    meta: { requiresAuth: true, role: ADMINISTRATIVE_ROLE },
  },
  {
    icon: "mdi-account",
    name: "Jenis Tabungan *",
    path: "/administrative-jenis-tabungan",
    component: () => import("../views/administrative/jenisTabungan.vue"),
    meta: { requiresAuth: true, role: ADMINISTRATIVE_ROLE },
  },
  {
    icon: "mdi-hand-coin-outline",
    name: "Simpanan *",
    path: "/administrative-simpanan",
    component: () => import("../views/administrative/simpanan.vue"),
    meta: { requiresAuth: true, role: ADMINISTRATIVE_ROLE },
  },
  {
    icon: "mdi-hand-coin",
    name: "Pinjaman *",
    path: "/administrative-pinjaman",
    component: () => import("../views/administrative/pinjaman.vue"),
    meta: { requiresAuth: true, role: ADMINISTRATIVE_ROLE },
  },{
    icon: "mdi-hand-coin-outline",
    name: "Tabungan Master *",
    path: "/administrative-tabungan-master",
    component: () => import("../views/administrative/tabunganMaster.vue"),
    meta: { requiresAuth: true, role: ADMINISTRATIVE_ROLE },
  },
  {
    icon: "mdi-hand-coin-outline",
    name: "Tabungan Entry *",
    path: "/administrative-tabungan-entry",
    component: () => import("../views/administrative/tabunganEntry.vue"),
    meta: { requiresAuth: true, role: ADMINISTRATIVE_ROLE },
  },
  {
    icon: "mdi-history",
    name: "Login Log *",
    path: "/administrative-login-log",
    component: () => import("../views/administrative/loginLog.vue"),
    meta: { requiresAuth: true, role: ADMINISTRATIVE_ROLE },
  },
];

const protectedRoutesAttendanceChecker = [
  {
    icon: "mdi-view-dashboard",
    name: "Dashboard Checker",
    path: "/attendance-checker-dashboard",
    component: () => import("../views/attendance-checker/dashboard.vue"),
    meta: { requiresAuth: true, role: ATTENDANCECHECKER_ROLE },
  },
  {
    icon: "mdi-clock-check-outline",
    name: "Attendance Checker",
    path: "/attendance-checker-attendance",
    component: () => import("../views/attendance-checker/attendanceStudents.vue"),
    meta: { requiresAuth: true, role: ATTENDANCECHECKER_ROLE },
  }, {
    icon: "mdi-printer",
    name: "Report* Checker",
    path: "/attendance-checker-guest-attendance-report",
    component: () => import("../views/attendance-checker/attendanceReport.vue"),
    meta: { requiresAuth: true, role: ATTENDANCECHECKER_ROLE },
  },
];

const protectedRoutesAttendanceGuest = [
  {
    icon: "mdi-view-dashboard",
    name: "Dashboard Guest",
    path: "/attendance-guest-dashboard",
    component: () => import("../views/attendance-guest/dashboard.vue"),
    meta: { requiresAuth: true, role: ATTENDANCEGUEST_ROLE },
  },
  {
    icon: "mdi-clock-check-outline",
    name: "Attendance Guest",
    path: "/attendance-guest-attendance",
    component: () => import("../views/attendance-guest/attendanceStudents.vue"),
    meta: { requiresAuth: true, role: ATTENDANCEGUEST_ROLE },
  },
  {
    icon: "mdi-printer",
    name: "Report Guest",
    path: "/attendance-guest-report",
    component: () => import("../views/attendance-guest/attendanceReport.vue"),
    meta: { requiresAuth: true, role: ATTENDANCEGUEST_ROLE },
  },
];


const protectedRoutesUser = [
  {
    icon: "mdi-account",
    name: "Profile",
    path: "/user-profile",
    component: () => import("../views/user/profile.vue"),
    meta: { requiresAuth: true, role: USER_ROLE },
  },
];

const publicPagesLeft = [
  {
    name: "Home",
    path: "/",
    component: () => import("../views/public/home.vue"),
  },
  {
    name: "About",
    path: "/about",
    component: () => import("../views/public/about.vue"),
  },
  


];

const publicPagesRight = [
  {
    name: "Login",
    path: "/login",
    component: () => import("../views/auth/Login.vue"),
  },
  {
    name: "Register",
    path: "/register",
    component: () => import("../views/auth/Register.vue"),
  },
];

const forgotPassword = [
  {
    name: "Forgot Password?",
    path: "/forgot-password",
    component: () => import("../views/auth/ForgotPassword.vue"),
  },
  {
    name: "Reset Password",
    path: "/reset-password/:token",
    component: () => import("../views/auth/ResetPassword.vue"),
  },
];

export {
  publicPagesLeft,
  publicPagesRight,
  protectedRoutesAdministrative,
  protectedRoutesAttendanceChecker,
  protectedRoutesAttendanceGuest,

  protectedRoutesUser,
  forgotPassword,
};
